@import "../../../scss/_includes";

.r-resume-features {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 .75rem;
  color: $text-color;
  font-size: .875rem;
  list-style-type: none;
}

.r-resume-features li {
  display: inline-flex;
  align-items: center;
  gap: .25rem;
  .tabler-icon { opacity: .325; width: 16px; stroke-width: 3px; }
}
