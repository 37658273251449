@import "../../../scss/_includes";

.r-btn {
  display: block;
  box-sizing: border-box;
  border-radius: 3rem;
  border: 1.5px solid $business-color;
  background: $business-color;
  padding: .75rem 2rem;
  text-align: center;
  line-height: 1.125rem;
  font-weight: 400;
  font-size: 0.875rem;
  cursor: pointer;
  &, &:active, &:hover { color: txt-color-bg-related($business-color) !important; }

  &.bolder { font-weight: 500; }

  &.large { padding: 1.125rem 2rem; }

  &.small { padding: .5rem 1.25rem; }

  &.secondary {
    border-color: $heading-color;
    background: $heading-color;
    color: txt-color-bg-related($heading-color) !important;
    &.light { border-color: $border-color; }
  }

  &.tertiary {
    border-color: $border-light-color;
    background: $border-light-color;
    color: $heading-color !important;
  }

  &.light {
    background: $has-white-color;
    color: txt-color-bg-related($has-white-color) !important;
  }

  &.link {
    background: transparent;
    border-color: transparent;
    color: txt-color-bg-related($has-white-color) !important;
    text-decoration: underline;
    padding-left: .625rem;
    padding-right: .625rem;
  }

  &.link:hover { background: $border-light-color; }

  &.rounded-icon,
  &.inline-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &.rounded-icon { padding: .75rem; }
  &.inline-icon { gap: .625rem; }

  &:disabled {
    opacity: .5;
    pointer-events: none;
  }
}

.readmore-inline {
  margin: .2125rem 0 0 auto;
  text-decoration: none !important;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
