@import "../../../scss/_includes";

.r-tooltip-trigger { cursor: pointer; }

.r-tooltip {
  --inner-padding: 1.25rem;
  .closer { --closer-padding: .25rem; }
}

.r-tooltip {
  z-index: 10;
  max-width: 20rem;
  border-radius: .75rem;
  background: $has-white-color;
  @include boxing-shadow();
  padding: var(--inner-padding);
  font-size: .875rem;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  line-height: 1.25rem;
  color: $has-txt-gray-color;
}

.r-tooltip:not(.inline) header {
  display: inline-flex;
  align-items: center;
  gap: .375rem;
  color: $business-color;
  font-weight: 500;
}

.r-tooltip .closer {
  padding: var(--closer-padding);
  outline: none;
  .tabler-icon { width: 1rem; height: 1rem; }
}

.r-tooltip.inline {
  padding-left: calc(#{var(--inner-padding)} + 1.5rem + .75rem);
  .closer {
    position: absolute;
    left: calc(#{var(--inner-padding)} - #{var(--closer-padding)});
    top: calc(#{var(--inner-padding)} - #{var(--closer-padding)});
  }
}

.r-tooltip p {
  margin: 0;
  & ~ p { margin-top: .325rem; }
}
