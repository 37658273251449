@import "../../../scss/_includes";

.rlw {
  --x-padding: #{map-get($x-paddings, mobile)};
  --slider-shift: #{slideable-wrapper-init(mobile)};
  --title-spacing: 2.5rem;
}

.rlw-centered,
.rlw-backgrounded > .rlw-backgrounded-wrapper,
.rlw-backgrounded.boxed {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--x-padding);
  padding-right: var(--x-padding);
  width: 100%;
  box-sizing: border-box;
  max-width: map-get($breakpoints, big-screen);
}

.rlw-backgrounded.boxed {
  display: grid;
  .rlw-backgrounded-wrapper, .rlw-backgrounded-img { grid-area: 1 / 1 / 2 / 2; }
  .rlw-backgrounded-img {
    border-radius: 1.25rem;
    background-position: center;
  }
  .rlw-backgrounded-wrapper {
    position: relative;
    padding: var(--x-padding);
  }
}

.rlw-backgrounded {
  position: relative;
  margin: 0 auto;
  max-width: calc(#{map-get($breakpoints, big-screen)} + 2 * #{map-get($x-paddings, big-screen)});

  > .rlw-backgrounded-img {
    position: relative;
    width: 100%;
    padding-bottom: var(--ratio-bg);
    background: var(--image-bg) no-repeat bottom center;
    background-size: cover;
    z-index: -1;
  }
}

.rlw-title {
  margin: 0;
  font-size: 1.375rem;
  font-weight: 600;
  color: $has-black-color;
}

.rlw-title,
.rlw-shifted-slider .slider-head { margin-bottom: var(--title-spacing); }

.rlw-shifted-slider .slider-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rlw-title { margin: 0; }
  .slider-nav { display: none; }
}

.rlw-shifted-slider .slider-head button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border: none;
  border-radius: 3rem;
  background: $border-color;
  color: $heading-color;
  cursor: pointer;
  & > .tabler-icon { flex: 0 0 1.5rem; height: 1.5rem; }
  &:disabled {
    pointer-events: none;
    opacity: .5;
  }
  &:not(:disabled):hover {
    background: $heading-color;
    color: $has-white-color;
  }
}

.rlw-shifted-slider .slider-content {
  overflow: hidden;
  .slider-wrapper {
    margin-right: var(--x-padding);
    margin-left: var(--slider-shift);
    display: flex;
    gap: .75rem;
  }
}

@include break-up(mobile) {

  .rlw {
    --x-padding: #{map-get($x-paddings, tablet)};
    --slider-shift: #{slideable-wrapper-init(tablet)};
  }

  .rlw-shifted-slider .slider-head .slider-nav {
    display: flex;
    gap: .75rem;
  }

}


@include break-up(tablet) {
  .rlw {
    --x-padding: #{map-get($x-paddings, desktop)};
    --slider-shift: #{slideable-wrapper-init(desktop)};
  }
}


@include break-up(desktop) {

  .rlw {
    --slider-shift: #{slideable-wrapper-init(big-desktop)};
    --title-spacing: 3.6rem;
  }

  .rlw-title { font-size: 1.75rem; }

}


@include break-up(big-desktop) {
  .rlw {
    --x-padding: #{map-get($x-paddings, big-screen)};
    --slider-shift: #{slideable-wrapper-init(big-screen)};
  }
}


@include break-up(big-screen) {

  .rlw {
    --title-spacing: 3.75rem;
  }

  .rlw-backgrounded.gradient > .rlw-backgrounded-img {
    &:before {
      content: "";
      position: absolute;
      width: 10rem;
      height: 100%;
      background: linear-gradient(to left, transparent 0, white 100%);
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 10rem;
      height: 100%;
      background: linear-gradient(to right, transparent 0, white 100%);
    }
  }

}
