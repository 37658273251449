@import "../../../scss/_includes";

.r-stepper-wrapper {
  --gap-size: .5rem;
  --badge-size: 3rem;
  --icon-rate: .5;
  --connector-width: 3px;
  --label-size: .825rem;
}

.r-stepper-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--gap-size);
}

.r-stepper-wrapper .one-step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-size);
  padding-bottom: calc(2 * #{var(--label-size)});
}

.r-stepper-wrapper .one-step .badge {
  border-radius: 50%;
  background: rgba(#707070, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--badge-size);
  height: var(--badge-size);
}

.r-stepper-wrapper .one-step .badge .tabler-icon {
  width: calc(#{var(--badge-size)} * #{var(--icon-rate)});
  height: calc(#{var(--badge-size)} * #{var(--icon-rate)});
}

.r-stepper-wrapper .one-step .label {
  position: absolute;
  bottom: 0;
  width: max-content;
  font-size: var(--label-size);
  color: #232323;
}

.r-stepper-wrapper .one-step.selected .badge {
  background: $business-color;
  color: $has-white-color;
}

.r-stepper-wrapper .connector {
  flex: auto;
  height: var(--connector-width);
  margin: calc((#{var(--badge-size)} / 2) - #{var(--connector-width)}) 0;
  border-radius: 1.5px;
  background: rgba(#707070, .2);
  &.selected { background: $business-color; }
}

@include break-up(mobile) {
  .r-stepper-wrapper {
    --gap-size: 1rem;
    --badge-size: 5rem;
  }
}


@include break-up(big-desktop) {
  .r-stepper-wrapper {
    --gap-size: 2rem;
    --badge-size: 6rem;
    --connector-width: 4px;
    --label-size: .875rem;
  }
}
