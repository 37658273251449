@import "../../../scss/_includes";

.rls-pagination-wrapper ul {
  list-style-type: none;
  padding-inline-start: 0;
  display: inline-flex;
  gap: .25rem;
}

.rls-pagination-wrapper li button,
.rls-pagination-wrapper li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border: none;
  border-radius: 50%;
  background: $has-white-color;
  color: $heading-color;
  padding: 0;
  cursor: pointer;
  &.dots {
    pointer-events: none;
    .tabler-icon { stroke-width: 1; }
  }
}

.rls-pagination-wrapper li button:disabled,
.rls-pagination-wrapper li a[aria-disabled="true"] {
  cursor: not-allowed;
  color: $border-color !important;
}

.rls-pagination-wrapper li button:not(:disabled):hover,
.rls-pagination-wrapper li a[aria-disabled="false"]:hover { background: $border-light-color; }

.rls-pagination-wrapper li.current button,
.rls-pagination-wrapper li.current a {
  background: $heading-color;
  color: $has-white-color;
  pointer-events: none;
}
