@import "../../../../scss/_includes";

.rla-title .rate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  margin-bottom: 1rem;
  &.scroll { cursor: pointer; }
}

.rla-title .rate .label {
  box-sizing: border-box;
  min-width: 7.875rem;
  border-radius: 2rem;
  padding: .5rem 1.325rem;
  color: $valid-color;
  text-align: center;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  font-size: .875rem;
  font-weight: 400;
  &.active { background: $valid-bg-color; }
}

.rla-title .rate .value {
  --bg-color: #{$skeleton-bg-color};
  --filling-color: #f8d51e;
  display: flex;
  align-content: center;
  gap: .25rem;
}

.rla-title .title-data {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: auto min-content;
  align-items: center;
  gap: .375rem;
}

.rla-title h1 {
  grid-area: 1 / 1 / 2 / 3;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: $heading-color;
}

.rla-title .address {
  grid-area: 2 / 1 / 3 / 3;
  display: block;
  font-size: .875rem;
}

.rla-title .average-price {
  grid-area: 3 / 1 / 4 / 2;
  display: block;
  font-size: 1rem;
  color: $heading-color;
  text-transform: uppercase;
  strong { font-weight: 600; }
}

.rla-title .sharing {
  grid-area: 3 / 2 / 4 / 3;
  justify-self: flex-end;
  .r-btn { min-width: 1rem; height: 1rem; box-sizing: content-box; }
  .r-btn .label { display: none; }
  .r-btn .tabler-icon { width: 1.25rem; height: 1.25rem; }
}


@include break-up(mobile) {
  .rla-title .sharing .r-btn .label { display: block; }
}

@include break-up(tablet) {
  .rla-title .rate { justify-content: flex-start; }
}
