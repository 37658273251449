@import "../../../../scss/_includes";

.rla-reviews .title h2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .75rem;
}

.rla-reviews .title h2 .average {
  display: inline-flex;
  align-items: center;
  color: $heading-color;
  gap: .325rem;
  font-size: 1.125rem;
  .tabler-icon {
    stroke: #f8d51e;
    stroke-width: 3;
    width: 1.125rem;
    height: 1.125rem;
  }
}

.rla-reviews .title h2 .count {
  text-transform: none;
  color: $heading-color;
}

.rla-reviews .ratings-list {
  display: flex;
  flex-wrap: wrap;
  gap: .75rem 10%;
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  font-weight: 400;
  & > .a-rating { flex: 100%; }
}

.rla-reviews .a-rating {
  display: inline-flex;
  align-items: center;
  gap: 2rem;
}

.rla-reviews .a-rating .criteria { flex: 0 0 40%; }

.rla-reviews .a-rating .value {
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  flex: 1 1 auto;
  .bar {
    border-radius: .25rem;
    background: $border-light-color;
    height: .375rem;
    flex: 1 1 auto;
    & > span {
      display: block;
      height: 100%;
      width: calc(#{var(--bar-rating)} / 5 * 100%);
      border-radius: .25rem;
      background: $heading-color;
    }
  }
  & > span {
    font-size: .75rem;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    color: $heading-color;
  }
}

@include break-up(mobile) {
  .rla-reviews .ratings-list > .a-rating { flex: 0 0 calc(50% - 5%); }
}


/************/
/* Comments */
/************/

.rla-comments-wrapper .slider-head {
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  h2 {
    font-family: "Rubik", sans-serif;
    font-size: .875rem;
    font-weight: 500;
    color: $heading-color;
    text-transform: uppercase;
  }
}

.rla-comments.grid {
  display: inline-flex;
  align-items: stretch;
  gap: .75rem;
  .rw-card { flex: 0 0 20rem; }
  .rw-card.show-all {
    border: 1.5px solid $border-light-color;
    background: transparent;
  }
}
