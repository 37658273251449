@import "/src/scss/_includes";

.rlh-menu-items {
  list-style-type: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: .475rem;
}

.rlh-menu-items li.buy { display: none; }

.rlh-menu-items li a:not(.has-btn) {
  border-radius: 2rem;
  padding: .625rem;
  &:hover, &:active { background: $border-light-color; }
}

.mb-menu-overlay {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 1000;
  inset: 70px 0 0 0 !important;
  background: $has-white-color;
}

.mb-menu-overlay .rlh-menu-items {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  font-size: 1.425rem;
  gap: 2.5rem;
}
