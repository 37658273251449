@import "../../../../scss/_includes";

.rla-features .features-list {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem 2rem;
  & > .a-feature {
    flex: 100%;
    min-height: 1.5rem;
  }
}

.rla-features .a-feature {
  display: inline-flex;
  align-items: center;
  gap: .5rem;
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  font-weight: 400;
  &.no {
    text-decoration: line-through;
    & > svg { opacity: .5; }
  }
  &.mask { display: none; }
}

.rla-features .a-feature .tabler-icon,
.rla-features .a-feature svg.custom {
  flex: 0 0 1.5rem;
  height: 1.5rem;
}


@include break-up(desktop) {
  .rla-features .features-list > .a-feature {
    flex: 0 0 calc(50% - 1rem);
  }
}
