@import "../../../../scss/_includes";

.r-pi-in-out-group {
  width: 100%;
  border: 1px solid $has-txt-gray-color;
  border-radius: 2rem;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: stretch;
  gap: .5rem;
  padding: 0 .5rem;

  &:not(.compact) { height: 3.375rem; }
}

.r-pi-field {

  flex: 1 1 0;
  box-sizing: border-box;
  padding: .5rem;
  color: $has-black-color;

  .r-pi-in-out-group.compact & { padding: .3125rem; }

  label {
    position: absolute;
    top: .125rem;
    left: 0;
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 400;
  }

  .r-pi-input {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .tabler-icon { display: none; }

  input {
    width: 100% !important;
    align-self: stretch;
    border: none;
    outline: none !important;
    background: transparent;
    padding: 1rem 0 .125rem;
    font-size: .825rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::placeholder { font-weight: 400; color: $text-color; }
  }

}


@include break-up(tablet) {
  .r-pi-field .r-pi-input .tabler-icon { display: flex; }
}
