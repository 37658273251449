@import "../../../../scss/_includes";

.rla-choice-resume {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.75rem;
  font-size: .875rem;
}

.rla-choice-resume .r-img {
  border-radius: .625rem;
  flex: 0 0 4rem;
}

.rla-choice-resume .metas {
  flex: 1;
  line-height: 1.25;
  & > span { display: block; }
  .location {
    font-size: .825rem;
    color: $text-color;
  }
  .period { font-weight: 500; font-size: .825rem;}
}

.rla-choice-resume .price {
  flex: 0 0 min-content;
  align-self: flex-start;
  font-size: 1rem;
  font-weight: 500;
}
