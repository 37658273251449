@import "../../../../scss/_includes";

.doc-list {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  &.skeleton { width: 100%; }
}

.a-doc {
  display: inline-flex;
  align-items: center;
  gap: 1.25rem;
}

.a-doc .doc-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: $border-color;
  border-radius: 50%;
  color: $business-color;
  padding: .625rem;
  .tabler-icon { scale: 1.175; }
}

.a-doc .doc-detail {
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: $heading-color;
}

.a-doc .doc-detail > span {
  display: block;
  color:$text-color;
  font-weight: 400;
}
