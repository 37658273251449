@import "/src/scss/_includes";

.r-price.discounted {

  display: inline-flex;
  align-items: center;
  gap: .5rem;
  color: $sale-color;
  font-weight: 500;

  .origin {
    font-size: .875em;
    font-weight: 400;
    color: $heading-color;
    text-decoration: line-through;
  }

}
