@import "../../../scss/_includes";

.agency-cbox {
  --inner-space: .75rem;
}

.agency-cbox {
  display: inline-block;
  border-radius: .625rem;
  background: $border-light-color;
  font-family: "Rubik", sans-serif;
  font-size: .875rem;
  color: $heading-color;
}

.agency-cbox .cbox-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: var(--inner-space) 0;
  font-weight: 500;
}

.agency-cbox .cbox-header .picto {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 2.5rem;
  height: 2.5rem;
  color: $business-color;
  .tabler-icon { z-index: 1; }
}

.agency-cbox .cbox-header .picto:before {
  content: "";
  display: block;
  position: absolute;
  width: 150%;
  height: 150%;
  top: 0;
  left: 0;
  z-index: 0;
  margin: -25% 0 0 -50%;
  border-radius: 50%;
  background: $business-light-bg-color;
}

.agency-cbox > *:not(.cbox-header) { margin: var(--inner-space); }
.agency-cbox .cbox-footer .r-btn { width: 100%; }

.agency-cbox address {
  display: flex;
  flex-direction: column;
  gap: .375rem;
  font-style: normal;
}

.agency-cbox address .iconized {
  display: inline-flex;
  align-items: flex-start;
  gap: .75rem;
  .tabler-icon { width: 1rem; height: 1rem; stroke: $business-color; }
}

@include break-up(tablet) {

  .agency-cbox {
    --inner-space: 1.125rem;
  }

}
