@import "../../../../scss/_includes";

.rla-reviews {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.rla-reviews .one-comment {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  font-family: "Rubik", sans-serif;
  font-size: .875rem;
  font-weight: 400;
  .client { color: $heading-color; }
}

.rla-reviews .one-comment .client .name {
  display: inline-flex;
  align-items: flex-start;
  gap: .75rem;
  padding-bottom: .175rem;
  font-size: 1rem;
  font-weight: 500;
  &.skeleton { display: flex; }
}

.rla-reviews .one-comment .client .rate {
  display: inline-flex;
  align-items: center;
  font-size: .875rem;
  gap: .325rem;
  .tabler-icon {
    stroke: #f8d51e;
    stroke-width: 3;
    width: 1.125rem;
    height: 1.125rem;
  }
}

.rla-reviews .one-comment .client .date { text-transform: capitalize; }

.rla-reviews .one-comment .comment {
  margin: 0;
  color: $text-color;
  line-height: 1.5;
  white-space: pre-line;
}

.rla-reviews .one-comment .answer {
  margin: .5rem 0 0 1rem;
  background: $border-light-color;
  border-radius: 1.25rem;
  padding: .75rem;

  .agency {
    display: inline-flex;
    gap: .5rem;
    margin-bottom: .5rem;
    span { color: $business-color; font-weight: 500; }
  }
}

@include break-up(tablet) {
  .rla-reviews .one-comment {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: minmax(25%, 1fr) 4fr;
    gap: 0 1rem;
    .answer { grid-area: 2 / 2 / 3 / 3; }
  }
}
