@import "/src/scss/_includes";

.main-nav-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  border-width: 0;
  border-radius: 2rem;
  background: transparent;
  padding: .625rem;
  cursor: pointer;
  .ico-smaller { width: 1rem; }
  &:not([disabled]):hover { background: $border-light-color; }
}

button.main-nav-item { color: $heading-color; }

.main-nav-item.bordered {
  $light-border-color: rgba($heading-color, .75);
  border-width: 1.5px;
  border-color: $light-border-color $heading-color $heading-color $light-border-color;
  padding: .4375rem;
  &:active { border-color: $heading-color $light-border-color $light-border-color $heading-color; }
}

.main-nav-item.badged {
  position: relative;
  > .badge {
    position: absolute;
    top: -.25rem;
    right: -.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: .325rem;
    background: $business-color;
    color: $has-white-color;
    font-size: .825rem;
    font-weight: 500;
  }
}

.main-nav-drop ul {
  list-style: none;
}

.main-nav-drop li {
  display: flex;
  align-items: center;
  padding: .375rem 1rem;
}

.main-nav-drop ul.chipped li {

  $chip-size: .375rem;
  $chip-gap: .625rem;

  column-gap: $chip-gap;
  padding-left: calc(.375rem - #{$chip-gap} - #{$chip-size});

  &:before {
    content: "";
    width: $chip-size;
    height: $chip-size;
    border-radius: 50%;
    background: transparent;
  }

  &.active {
    font-weight: 500;
    &:before { background: $business-color; }
  }

}


/* user menu */

.user-menu .wrap-user-ico {
  height: 1.5rem;
  position: relative;
  &.connected:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: .625rem;
    height: .625rem;
    border: 1px solid $has-white-color;
    border-radius: .625rem;
    background: $business-color;
  }
}
