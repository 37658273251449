@import "/src/scss/_includes";


/* overlay */

.r-modal-overlay {
  outline: none;
  --overlay-spacing: #{map-get($x-paddings, mobile)};
  --inner-spacing: #{map-get($x-paddings, mobile)};
  --rounded: 1.5rem;
}

.r-modal-overlay {
  z-index: 2000;
  box-sizing: border-box;
  background: rgba(0, 0, 0, .325);
  width: 100%;
  height: 100%;
  padding: calc(2 * #{var(--overlay-spacing)}) 0 0 0;
}


@include break-up(mobile) {
  .r-modal-overlay {
    --overlay-spacing: 2rem;
    --inner-spacing: #{map-get($x-paddings, tablet)};
    padding: var(--overlay-spacing);
  }
}


@include break-up(desktop) {
  .r-modal-overlay {
    --overlay-spacing: 4rem;
    --inner-spacing: #{map-get($x-paddings, big-desktop)};
  }
}


/* container */

.r-modal {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: max(var(--rounded), var(--inner-spacing));
  width: 100%;
  max-height: calc(100% - (2  * #{var(--overlay-spacing)}));
  box-sizing: border-box;
  border-radius: var(--rounded) var(--rounded) 0 0;
  padding: 0 0 var(--inner-spacing);
  background: $has-white-color;
  box-shadow: 0 1rem 2rem #46464666;
  color: $heading-color;
  &.full { height: calc(100% - (2  * #{var(--overlay-spacing)})); }
}

.r-modal .r-modal-header,
.r-modal .r-modal-content,
.r-modal .r-modal-footer { padding: 0 var(--inner-spacing); }

.r-modal .r-modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: .75rem;
  h1 { margin-top: max(var(--rounded), var(--inner-spacing)); }
  .r-modal-close {
    margin-top: calc(.5 * var(--inner-spacing));
    margin-right: calc(-.5 * var(--inner-spacing));
  }
}

.r-modal .r-modal-content::-webkit-scrollbar-track { margin: var(--rounded) 0; }
.r-modal .r-modal-content {
  max-width: 100%;
  overflow-y: scroll;
  overflow-y: overlay;
  padding-top: .25rem;
  padding-bottom: .25rem
}

.r-modal h1 {
  margin: 0;
  flex: 1;
  font-family: "Rubik", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
}

.r-modal .r-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: .75rem;
}


@include break-up(mobile) {
  .r-modal {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    max-height: 100%;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    &.sm { max-width: calc(#{map-get($breakpoints, mobile)} - (2 * #{var(--overlay-spacing)})); }
    &.md { max-width: calc(#{map-get($breakpoints, tablet)} - (2 * #{var(--overlay-spacing)})); }
    &.lg { max-width: calc(#{map-get($breakpoints, desktop)} - (2 * #{var(--overlay-spacing)})); }
  }
}
