@import "../../../scss/_includes";

@keyframes scale-animate {
  from { transform: scale(1); }
  to { transform: scale(1.1); }
}

$slider-spacing: .75rem;
$slider-bullet-size: 8px;
$slider-bullet-x-margin: 3px;

.rn-card {
  overflow: hidden;
  position: relative;
  border-radius: 1.25rem;
  font-family: "Rubik", sans-serif;
}

.rn-card a { display: block; z-index: 0; }

.rn-card .resume {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  border-radius: .625rem;
  width: calc(100% - (2 * #{$slider-spacing}));
  margin: $slider-spacing;
  @include blur-background();
  padding: $slider-spacing;
}

.rn-card h1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  color: $heading-color;
  &:has(~ .r-resume-features) { margin: 0 0 .325rem 0; }
}

.rn-card .resume .r-resume-features {
  gap: 0 .5rem;
  font-size: .825rem;
  color: $heading-color;
}

.rn-card .resume .book-date {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: flex-end;
  gap: .5rem;
  border-radius: 2rem;
  background: $business-color;
  padding: .325rem 1rem;
  color: $has-white-color;
  font-size: .875rem;
  &.discounted { background: $sale-color; }
  .tabler-icon { width: 1.125rem; height: 1.125rem; }
  + h1 { margin-top: $slider-spacing !important; }
}

.rn-card .slider {
  overflow: hidden;
  border-radius: 1.25rem;
}

.rn-card .slider-track {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  overflow: auto hidden;
  scroll-snap-type: x mandatory;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar { display: none; }
}

.rn-card .slider-item {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.rn-card .slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 50%;
  border: none;
  width: 2rem;
  height: 2rem;
  background: rgba($has-white-color, .9);
  cursor: pointer;
  color: $heading-color;
  transition: opacity .25s ease;
  &.left { left: $slider-spacing; }
  &.right { right: $slider-spacing; }
  & > .tabler-icon { flex: 0 0 1.25rem; height: 1.25rem; }
}

.rn-card .slider-bullets {
  position: absolute;
  overflow: hidden;
  top: calc(-2 * $slider-spacing);
  left: 50%;
  transform: translateX(-50%);
  max-width: calc((#{$slider-bullet-size} + (2 * #{$slider-bullet-x-margin})) * 5);
  list-style-type: none;
  &:has(+ .book-date) { top: calc(-3 * $slider-spacing); }
}

.rn-card .slider-bullets-track {
  display: flex;
  overflow: auto hidden;
  list-style-type: none;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar { display: none; }
}

.rn-card .slider-bullets-item {
  flex: 0 0 auto;
  padding: 0 $slider-bullet-x-margin;
  &:after {
    content: "";
    display: block;
    border-radius: 50%;
    width: $slider-bullet-size;
    height: $slider-bullet-size;
    background: rgba($has-white-color, .5);
  }
  &.active:after { background: $has-white-color; }
}

.rn-card .discount-wrapper {
  --fixed-height: 2.75rem;
}

.rn-card .discount-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  align-items: flex-start;
  gap: .75rem;
  box-sizing: border-box;
  margin: $slider-spacing;
  color: $has-white-color;
  font-size: .875rem;
  &.visible { width: calc(100% - (2 * #{$slider-spacing})); }
}

.rn-card .discount-box {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: var(--fixed-height);
  border-radius: .625rem;
  background: scale-color($sale-color, $lightness: 10%);
  padding: calc(#{$slider-spacing} / 2);
  cursor: default;
  .tabler-icon {
    width: 2rem;
    height: 2rem;
    stroke-width: 2.5px;
    animation: 1s scale-animate infinite alternate;
  }
}

.rn-card .discounts-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: calc(#{$slider-spacing} / 2);
}

.rn-card .one-discount {
  height: var(--fixed-height);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: repeat(2, 1fr);
  align-items: stretch;
  border-radius: .625rem;
  background: scale-color($sale-color, $lightness: 10%);
  padding: 0 calc(#{$slider-spacing} / 2);

  & > .value {
    align-self: flex-end;
    grid-area: 1 / 1 / 2 / 2;
    .value { font-weight: 600; }
  }

  .period {
    align-self: flex-start;
    grid-area: 2 / 1 / 3 / 2;
    display: inline-flex;
    align-items: flex-end;
    gap: .5rem;
    & > span:first-letter { text-transform: lowercase; }
    .tabler-icon { width: 1.125rem; height: 1.125rem; }
  }

  .targetable {
    grid-area: 1 / 2 / 3 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.rn-card.map-view .resume {
  position: relative;
  margin: 0;
  width: 100%;
  border-radius: 0 0 1.25rem 1.25rem;
  background:  $has-white-color;
  @include clean-blur-background();
}

.rn-card.map-view .slider { border-radius: 0; }
.rn-card.map-view .slider-track { overflow: hidden; }
.rn-card.map-view .slider-arrow, .rn-card.map-view .slider-bullets { display: none; }

.rn-card .legacy-discount {
  position: absolute;
  top: 0;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 10fr;
  align-items: center;
  gap: 0 .75rem;
  box-sizing: border-box;
  border-radius: .625rem;
  background: scale-color($sale-color, $lightness: 10%);
  margin: $slider-spacing;
  width: calc(100% - (2 * #{$slider-spacing}));
  padding: calc(#{$slider-spacing} / 2) calc(#{$slider-spacing} / 2) calc(#{$slider-spacing} / 2) .75rem;
  color: $has-white-color;
  font-size: .875rem;
}


@media (hover: none) {
  .rn-card .slider-arrow:not(.mask) { opacity: 1 !important; }
}

@media (hover: hover) and (pointer: fine) {
  .rn-card:hover .slider-arrow:not(.mask) { opacity: 1; }
}
