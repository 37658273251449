@import "/src/scss/_includes";

.r-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.r-form label {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: .875rem;
  line-height: 1.5;
  color: $heading-color;
}

.r-form input,
.r-form textarea,
.r-form select {
  border-radius: .625rem;
  border: 1.5px solid $has-txt-gray-color;
  padding: .425rem .75rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: .875rem;
  line-height: 1.5;
  color: $heading-color;
}

.r-form textarea {
  min-height: 12.5rem;
  resize: none;
}

.r-form select {
  padding-right: 2rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-chevron-down' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='#{url-friendly-colour($has-bg-gray-color)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 9l6 6l6 -6' /%3E%3C/svg%3E");
  background-position: right .25rem top 50%;
  background-repeat: no-repeat;
}

.r-form input::placeholder,
.r-form textarea::placeholder,
.r-form select:not(:valid) {
  font-weight: 400;
  color: $placeholder-color;
}

.r-form input:focus,
.r-form textarea:focus,
.r-form select:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem $border-light-color;
}

.r-form input.dirty:not(:focus),
.r-form textarea.dirty:not(:focus),
.r-form select.dirty:not(:focus) { border-color: $booked-bg-color; }

.r-form .rf-checkbox {

  position: relative;
  width: 1rem;
  height: 1rem;

  input[type="checkbox"] {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .rf-mark {
    display: block;
    box-sizing: border-box;
    border-radius: 1rem;
    background: $business-opacity-bg-color;
    width: 1rem;
    height: 1rem;
  }

  input[type="checkbox"]:checked ~ .rf-mark {
    border: .325rem solid $business-color;
    background: $has-white-color;
  }

  input[type="checkbox"]:disabled {
    & ~ .rf-mark, & ~ label { opacity: .5; }
  }

}

.r-form input.street-min { max-width: 5ch; }
.r-form input.zip-min { max-width: 8ch; }

.rf-error {
  margin: .25rem 0 0;
  min-height: .825rem;
  font-size: .825rem;
  line-height: .825rem;
  color: $booked-bg-color;
}

.rf-valid-group {
  > input, textarea, select {
    box-sizing: border-box;
    width: 100%;
  }
}

.rf-line-group {
  display: flex;
  align-items: center;
  gap: 1rem;
  > input { flex: 1; }
}

.rf-submit-group {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.rf-submitter { width: max-content; }

.rf-submitter.loadable-keeping-size {
  position: relative;
  &:not(.loading) { .loader { display: none; } }
  &.loading {
    pointer-events: none;
    .text { visibility: hidden; }
    .loader {
      position: absolute;
      display: flex;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .tabler-icon { animation: rotate .5s linear infinite; }
    }
  }
}

.rf-submit-group .callback {

  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  font-family: "Rubik", sans-serif;
  font-size: .825rem;
  font-weight: 500;
  color: $text-color;

  .tabler-icon {
    flex: 0 0 1rem;
    height: 1rem;
    margin-top: -1px;
    stroke-width: 4;
    color: $valid-color;
  }

  &.fail .tabler-icon { color: $error-color; }

}

@include break-only(mobile) {
  .rf-submit-group { flex-direction: row-reverse; }
}
