@import "../../../../scss/_includes";

/* selection */

.region-input-choice {
  overflow-y: scroll;
  overflow-y: overlay;
  box-sizing: border-box;
  max-height: 20rem;
  ul { list-style-type: none; }
}

.region-input-choice .selectable-region {
  border-radius: .625rem;
  border: 2px solid $has-white-color;
  cursor: pointer;
  font-size: .925rem;
  font-weight: 500;
  padding: .75rem .5rem;
  &:hover, &.selected { background: $business-light-bg-color; }
}

/* desktop view */

.rf-searcher .input-wrap.regions { min-width: 40%; }
.rf-searcher .input-wrap.regions .input-val-state.empty .label {
  color: $heading-color;
  font-weight: 600;
}

.rf-searcher-input-dropdown.regions {
  min-width: 20.75rem;
  padding: .75rem;
}

/* mobile view */

.rf-searcher-mobile-modal-wrap .input-wrap.regions .input-val-state .label { display: none !important; }
.rf-searcher-mobile-modal-wrap .region-input-choice .selectable-region { border-color: $border-light-color; }
