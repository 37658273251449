@import "../../../scss/_includes";

.rls-search-counted-skeleton { width: 25%; }

.rls-search-counted-result {
  font-weight: 400;
  font-size: .875rem;
  color: $heading-color;
  &.empty { font-size: 1.25rem; }
}

.rls-search-counted-helper {
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  line-height: 1.25rem;
}
