@import "_theme";

@function txt-color-bg-related($color) {
  @if (lightness($color) < 50) {
    @return $has-white-color;
  }
  @else {
    @return $has-black-color;
  }
}

@function url-friendly-colour($colour) {
  @return '%23' + str-slice('#{$colour}', 2, -1)
}

@mixin boxing-shadow($border-size: .5px) {
  border: $border-size solid $border-light-color;
  box-shadow: 0 0 1.25rem $border-shadow-color;
}

@mixin blur-background {
  background: rgba($has-white-color, .625);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

@mixin clean-blur-background {
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}
