@import "../../../scss/_includes";

.rla-book-choice,
.rla-book-picker-float-wrapper {
  --inner-gap: .475rem;
  --period-resume-height: 3.375rem;
}

// booking box

.rla-book-preview + .rla-book-choice { margin-top: var(--inner-gap); }

.rla-book-choice {
  display: flex;
  flex-direction: column;
  gap: var(--inner-gap);
}

.rla-book-choice .resume-period-wrapper {
  border: none;
  padding: 0;
  background: transparent;
  &, & * { cursor: pointer; }
  &:disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.rla-book-choice .action-button {

  .icon-loading {
    width: .875rem;
    height: .875rem;
    scale: 2;
    stroke-width: 1.5px;
    animation: rotate .5s linear infinite;
  }

}

.rla-book-choice .conflicted {
  display: flex;
  gap: .375rem;
  align-items: center;
  justify-content: center;
  line-height: 1.325;
  border-radius: .375rem;
  padding: .5rem;
  background: $error-bg-color;
  font-size: .825rem;
  font-family: "Inter", sans-serif;
  color: $error-color;
  font-weight: 500;
  text-align: center;
  .tabler-icon { width: 2rem; height: 2rem; }
}

.rla-book-choice .textual-ondemand {
  text-align: center;
  font-size: .825rem;
  font-family: "Inter", sans-serif;
  color: $text-color;
}


// booking box mobile

.rla-book-choice.fixed {

  flex-direction: row;
  align-items: center;
  gap: calc(2 * #{var(--inner-gap)});

  .book-price-resume {
    flex: 1;
    display: grid;
    grid-template: auto / 1fr;
    gap: .225rem;
    font-size: .875rem;
    color: $heading-color;
    line-height: 1rem;
  }

  .book-price-resume .tabler-icon { width: 1.25rem; height: 1.25rem; color: $business-color; }

  .book-price-resume .price,
  .book-price-resume .period {
    display: inline-flex;
    align-items: center;
    gap: .5rem;
  }

  .book-price-resume .price {
    font-size: 1rem;
    font-weight: 500;
  }

  .book-price-resume .price.detailed,
  .book-price-resume .period { text-decoration: underline; }

  .r-btn {
    flex: 0 0 45%;
    min-width: 45%;
    min-height: 3.75rem;
    border-radius: .625rem;
  }

}

// floating picker wrapper

.rla-book-picker-float-wrapper {
  --picker-border: .5px;
  --picker-padding: .85rem;
}

.rla-book-picker-float-wrapper {
  z-index: 501;
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
  border-radius: 1.25rem;
  background: $has-white-color;
  @include boxing-shadow(var(--picker-border));
  padding: var(--picker-padding);

  &:not(.mobile) {
    margin-top: calc(-1 * (#{var(--inner-gap)} + #{var(--picker-border)} + #{var(--picker-padding)} + #{var(--period-resume-height)}));
    margin-left: calc(#{var(--picker-padding)} + #{var(--picker-border)});
  }

  &.mobile {
    width: calc(100% - #{map-get($x-paddings, mobile)});
    margin-top: -1.1875rem;
    margin-left: .75rem;
  }

}

.rla-book-picker-float-wrapper .pickable-header,
.rla-book-picker-float-wrapper .pickable-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: .25rem;
}

.rla-book-picker-float-wrapper .pickable-header {
  width: 100%;
  justify-content: space-between;
}

.rla-book-picker-float-wrapper .pickable-header .priced-choice {

  flex: auto;
  display: flex;
  flex-direction: column;
  gap: .125rem;
  color: $has-black-color;

  .period {
    font-size: .875rem;
    font-weight: 500;
    color: $text-color;
  }

  .price {
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    font-weight: 600;
    font-size: 1.075rem;
  }

}

.rla-book-picker-float-wrapper .r-pi-in-out-group { max-width: calc(23.25rem - 1rem - 1px); }
.rla-book-picker-float-wrapper .ct-calendar__controllers__calendarWrapper { margin: 0 auto; }
.rla-book-picker-float-wrapper .ct-calendar__controllers__calendarDay.cds-valid { font-weight: 500; }
.rla-book-picker-float-wrapper .ct-calendar__controllers__calendarDayTooltip { font-weight: 400; }


/* media queries */

@include break-up(tablet) {
  .rla-book-choice, .rla-book-picker-float-wrapper {
    --inner-gap: .75rem;
  }
}

@include break-up(desktop) {
  .rla-book-picker-float-wrapper {
    --picker-padding: 1.5rem;
  }
}
