@import "../../../scss/_includes";

.filter-two-cols {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem 2rem;
  .a-filter-check {
    flex: 100%;
    line-height: 1.5rem;
    min-height: 1.5rem;
  }
}

.filter-two-cols .a-filter-check {
  position: relative;
  display: flex;
  align-items: center;
  gap: .625rem;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: .375rem;
    border: 1.5px solid $border-color;
    background: $has-white-color;
    padding: .125rem;
  }

  .checkmark > .tabler-icon {
    display: none;
    stroke-width: 4;
  }

  input:hover:not(:checked) ~.checkmark { border-color: $has-txt-gray-color; }

  input:checked ~.checkmark {
    background: $border-light-color;
    border-color: $has-txt-gray-color;
    box-shadow: inset 0 0 0 1px $has-txt-gray-color;
    & > .tabler-icon { display: block; }
  }

}

.filter-list-choice {
  --options-spacing: .5rem;
}

.filter-list-choice {
  display: inline-flex;
  gap: var(--options-spacing);
}

.filter-list-choice button {
  min-width: calc(2rem + 2 * 1.5px);
  border: 1.5px solid $border-color;
  border-radius: 1.25rem;
  background: $has-white-color;
  color: $heading-color;
  font-size: .825rem;
  line-height: 2rem;
  padding: 0 0.75rem;
  cursor: pointer;
  &:not(.selected):hover { border-color: $has-txt-gray-color; }
  &.selected {
    background: $border-light-color;
    border-color: $has-txt-gray-color;
    box-shadow: inset 0 0 0 1px $has-txt-gray-color;
  }
}

.filter-input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: .625rem;
  border: 1.5px solid $border-color;
  padding: .625rem 3rem .625rem .625rem;
}

.filter-input input {
  width: 100%;
  border: none;
  line-height: 1.5rem;
  outline: none !important;
}

.filter-input .resetter {
  position: absolute;
  right: .75rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: none;
  background: $border-color;
  padding: 0;
  cursor: pointer;
  color: $heading-color;
  .tabler-icon {
    flex: 0 0 .75rem;
    height: .75rem;
    stroke-width: 4px;
  }
}

@include break-up(mobile) {
  .filter-list-choice {
    --options-spacing: .75rem;
  }

  .filter-two-cols .a-filter-check { flex: calc(50% - 1rem); }
}
