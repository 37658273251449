@import "../../../scss/_includes";

.rla-options .r-modal-content > p {
  margin: 0 0 var(--inner-spacing) 0;
  color: $has-txt-gray-color;
  font-family: Inter, sans-serif;
  font-size: .875rem;
  line-height: 1.3;
}

.rla-options .options-form {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  &:not(.with-image) .one-option .option-img { display: none; }
}

.rla-options .one-option {
  display: flex;
  gap: 1.75rem;
}

.rla-options .one-option.tot {
  font-weight: 500;
  .desc { text-align: right; }
}

.rla-options .one-option .option-img {
  flex: 0 0 4rem;
  height: 4rem;
  border-radius: .625rem;
  background: $border-light-color;
  overflow: hidden;
}

.rla-options .one-option .option-desc {
  flex: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: .175rem;
}

.rla-options .one-option .amount {
  flex: 0 0 max-content;
}

.rla-options .one-option .option-desc .option-title {
  display: inline-flex;
  gap: .5rem;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
}

.rla-options .one-option .option-desc .option-title .icon {
  cursor: pointer;
  &, .tabler-icon {
    color: $business-color;
    width: 1.325rem;
    height: 1.325rem;
  }
}

.rla-options .one-option .option-criterion {
  display: flex;
  align-items: center;
  gap: .5rem;
  .rf-error {
    margin: 0;
    font-size: .875rem;
  }
}

.rla-options .r-modal-footer .r-btn.loadable-keeping-size {
  position: relative;
  &:not(.loading) { .loader { display: none; } }
  &.loading {
    pointer-events: none;
    .text { visibility: hidden; }
    .loader {
      position: absolute;
      display: flex;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .tabler-icon { animation: rotate .5s linear infinite; }
    }
  }
}


@include break-only(mobile) {
  .rla-options .one-option .option-img { display: none; }
}
