@import "../../../scss/_includes";

.last-minute-warning {
  display: flex;
  align-items: center;
  gap: .625rem;
  padding: .5rem;
  border-radius: .375rem;
  background: $business-light-bg-color;
}

.last-minute-warning .tabler-icon {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  color: $business-color;
}
