$breakpoints: (
  small-mobile: 375px,
  mobile: 576px,
  tablet: 768px,
  desktop: 992px,
  big-desktop: 1200px,
  big-screen: 1400px
);

$x-paddings: (
  small-mobile: .75rem,
  mobile: .75rem,
  tablet: 1rem,
  desktop: 1.5rem,
  big-desktop: 1.5rem,
  big-screen: 2.25rem
);

@function break-min($bp) {
  @return map-get($breakpoints, $bp);
}

@function break-max($bp) {
  $max: map-get($breakpoints, $bp);
  @return if($max and $max > 0, $max - .02, null);
}

@function valid-orientation($or) {
  @return if($or == "portrait" or $or == "landscape", $or, null);
}

@mixin break-up($bp, $or: null) {
  $min: break-min($bp);
  $orientation: valid-orientation($or);
  @if $min {
    @if $orientation {
      @media (min-width: $min) and (orientation: $orientation) { @content }
    } @else {
      @media (min-width: $min) { @content }
    }
  }
}

@mixin break-down($bp, $or: null) {
  $max: break-max($bp);
  $orientation: valid-orientation($or);
  @if $max {
    @if $orientation {
      @media (max-width: $max) and (orientation: $orientation) { @content }
    } @else {
      @media (max-width: $max) { @content }
    }
  }
}

@mixin break-only($bp, $or: null) {
  $list: map-keys($breakpoints);
  $i: index($list, $bp);
  $orientation: valid-orientation($or);
  @if ($i + 1 >= length($list)) {
    @include break-up($bp, $orientation) { @content; }
  } @else {
    @if $orientation {
      @media (max-width: map-get($breakpoints, nth($list, $i + 1))) and (orientation: $orientation) { @content; }
    } @else {
      @media (max-width: map-get($breakpoints, nth($list, $i + 1))) { @content; }
    }
  }
}

@function slideable-wrapper-init($bp) {
  $min-padding: map-get($x-paddings, $bp);
  @return max($min-padding, calc(((100vw - #{map-get($breakpoints, $bp)}) / 2) + #{$min-padding}));
}
