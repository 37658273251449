@import "../../../scss/_includes";

.rl-cart .account .account-options {
  margin: 0;
  display: grid;
  gap: .625rem;
  dt { line-height: 1.175rem; }
  dd ~ dt { margin-top: var(--item-spacing); }
  dd { margin-inline-start: 0;}
  dd .r-btn { width: 100%; }
}


@include break-up(big-desktop) {

  .rl-cart .account h2 { margin-bottom: 2rem; }

}
