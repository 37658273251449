@import "/src/scss/_includes";

.r-info-box {
  display: inline-flex;
  align-items: center;
  border-radius: .25rem;
  background: $border-light-color;
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  color: $text-color !important;
  @at-root a#{&}, &.asLink {
      font-family: "Rubik", sans-serif !important;
      font-weight: 500 !important;
      color: $heading-color !important;
  }
}

.r-info-box:has(~ *) { margin-bottom: .75rem; }

.r-info-box > .icon {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: .25rem 0 0 .25rem;
  height: 1.75rem;
  flex: 0 0 1.75rem;
  padding: 1rem;
  font-size: 1.125rem;
  color: $business-color !important;
  z-index: 0;
  .tabler-icon {
    width: 1.75rem;
    height: 1.75rem;
    z-index: 1;
  }
}

.r-info-box > .icon:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 150%;
  height: 150%;
  top: 0;
  right: 0;
  margin-top: -25%;
  border-radius: 50%;
  background: $business-light-bg-color;
}

.r-info-box > .txt { margin: .75rem 1rem; }

.r-info-box > .tabler-icon-chevrons-right {
  stroke: $business-color;
  stroke-width: 2.5;
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 1rem;
}

.r-info-box.reverse {
  justify-content: flex-end;
  .icon {
    order: 3;
    border-radius: 0 .25rem .25rem 0;
  }
  & > .icon:before { right: -50%; }
}

@include break-up(desktop) {
  .r-info-box:has(~ *) { margin-bottom: 1.5rem; }
}
