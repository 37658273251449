@import "../../../scss/_includes";

.cart-item-wrapper.callback .result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--item-spacing);

  .success-svg { width: 100%; }
  .success-svg, p { max-width: map-get($breakpoints, tablet); }

  .tabler-icon {
    max-width: 18.75rem;
    width: 50%;
    height: 50%;
    stroke: $booked-bg-color;
  }
}

.cart-item-wrapper.callback .result h1 {
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 500;
}

.cart-item-wrapper.callback .result p {
  font-size: .925rem;
  color: $text-color;
  text-align: center;
}

.cart-item-wrapper.callback .stepper-actions {
  display: flex;
  justify-content: center;
  button { flex: auto; }
}

@include break-up(tablet) {
  .cart-item-wrapper.callback .result h1 { font-size: 2rem; }
}


@include break-up(desktop) {
  .cart-item-wrapper.callback .stepper-actions button { max-width: 50%; }
}
