@import "/src/scss/_includes";

.r-dropdown {
  z-index: 2000;
  border-radius: 1.25rem;
  background: $has-white-color;
  @include boxing-shadow();
  padding: 1rem .75rem;
  color: $has-black-color;
  font-size: .875rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}
