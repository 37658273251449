// colors

$has-black-color: #000000;
$has-white-color: #ffffff;
$has-txt-gray-color: #7f7f7f;
$has-bg-gray-color: #d9d9d9;

$skeleton-bg-color: rgba($has-bg-gray-color, .2);
$skeleton-hl-color: #f5f5f5;

$border-color: scale-color($has-bg-gray-color, $lightness: 50%);
$border-light-color: scale-color($has-bg-gray-color, $lightness: 80%);
$border-shadow-color: $has-bg-gray-color;

$heading-color: $has-black-color;
$text-color: $has-txt-gray-color;
$placeholder-color: scale-color($has-txt-gray-color, $lightness: 20%);

$business-color: #19b8d3;
$business-light-bg-color: #ceeef3;
$business-opacity-bg-color: rgba($business-color, .2);

$sale-color: #e53f71;
$valid-color: #19d363;
$warning-color: #ffcc00;
$error-color: #c13515;

$warning-bg-color: scale-color($warning-color, $lightness: 75%);
$error-bg-color:scale-color($error-color, $lightness: 75%);

$valid-bg-color: scale-color($valid-color, $lightness: 90%);
$restricted-bg-color: scale-color($warning-color, $lightness: 50%);
$booked-bg-color: scale-color($error-color, $lightness: 75%);
