@import "../../../scss/_includes";

$padding: .5rem;

.rf-searcher {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3rem;
  border: .5 solid $border-light-color;
  background: $has-white-color;
  box-shadow: 0 .125rem 2.5rem $border-shadow-color;
  font-size: .825rem;
  color: $heading-color;
  & .inner-space { padding: $padding; }
  &.mobile .inner-space { padding: $padding calc(2 * #{$padding}) $padding $padding; }
}

.rf-searcher-mobile-modal-wrap .r-modal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rf-searcher-mobile-modal-wrap .r-modal-footer button { flex: 1; }
