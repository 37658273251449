@import "../../../scss/_includes";

.rl-cart {
  --skeleton-height: 32rem;
  --empty-rate: 1.25;
}

.cart-skeleton {
  min-height: var(--skeleton-height);
  border-radius: .625rem !important;
}

.rl-cart .cart-full-price.stepper-actions .cart-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .cart-price-detail.charge ~ .last-minute-warning { margin-top: -1.25rem; }
}

.rl-cart .cart-item-wrapper.empty-cart {
  padding: calc(#{var(--item-spacing)} * #{var(--empty-rate)}) var(--item-spacing);
}

.rl-cart .empty-cart .title {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: .375rem;
}

.rl-cart .empty-cart .redirect {
  display: inline-flex;
  a {
    display: flex;
    align-items: center;
    min-width: 1.5rem;
    padding-left: .25rem;
  }
  .tabler-icon {
    width: 1.125rem;
    height: 1.125rem;
    stroke: $business-color;
    stroke-width: 2.5;
  }
}


@include break-up(mobile) {
  .rl-cart .cart-full-price.rl-two-col {
    gap: calc((var(--item-spacing) * var(--col-spacing-rate)) - var(--item-spacing));
  }
}


@include break-up(tablet) {

  .rl-cart { --skeleton-height: 24rem; }

}
