@import "/src/scss/_includes";

.profile-form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
}

.profile-form ~ .rf-submit-group { margin-top: 1.5rem; }

@include break-up(mobile) {

  .profile-form {
    flex-direction: row;
    gap: 2.25rem;
    & > * { flex: 1; }
  }

}

@include break-up(desktop) {
  .profile-form { gap: 4.5rem; }
}
