@import "../../../scss/_includes";

.cart-price-detail h2 {
  margin: 0 0 1rem 0;
  font-size: 1.125rem;
  font-weight: 400;
  text-transform: uppercase;
  color: $has-txt-gray-color;
}

.cart-price-detail .price-list {
  display: grid;
  grid-template-columns: auto max-content;
  justify-content: space-between;
  margin: 0;
  line-height: 2.5rem;
  dd { text-align: right; }
  dd.discount {
    font-weight: 500;
    color: $valid-color;
  }
}

.cart-price-detail .charges .r-tooltip-trigger { text-decoration: underline; }
.cart-price-detail .charges .tooltip-more {
  display: inline-flex;
  align-items: center;
  gap: .425rem;
  .tabler-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: $business-color;
  }
}



.cart-price-detail .price-list.total {
  border-width: 1px 0;
  border-style: solid;
  border-color: $border-light-color;
  padding: .5rem 0;
  dd { font-weight: 500; }
}

.cart-price-detail .charges .r-tooltip-trigger { text-decoration: underline; }
.cart-price-detail .charges .tooltip-more {
  display: inline-flex;
  align-items: center;
  gap: .425rem;
  .tabler-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: $business-color;
  }
}

.cart-payment-resume {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
