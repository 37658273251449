@import "/src/scss/_includes";

.rls-view-switcher.mobile {
  z-index: 1;
  position: fixed;
  left: 50vw;
  bottom: 2rem;
  transform: translateX(-50%);
  gap: .75rem;
  box-shadow: 0 0 1rem .25rem #46464650;
}

.rls-view-switcher.desktop {
  z-index: 1001;
  position: absolute;
  top: 0;
  border-color: transparent;
  margin: 1.5rem;
}
