@import "../../../../scss/_includes";

.rla-book-preview {
  --feature-y-padding: .75rem;
  --feature-x-padding: .25rem;
}

.rla-book-preview { position: relative; }
.rla-book-preview .r-img { border-radius: .625rem; }

.rla-book-preview .r-resume-features {
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: space-around;
  column-gap: .25rem;
  border-radius: .625rem;
  @include blur-background();
  margin: var(--feature-y-padding);
  box-sizing: border-box;
  width: calc(100% - 2 * #{var(--feature-y-padding)});
  padding: var(--feature-y-padding) var(--feature-x-padding);
  color: $heading-color;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1rem;
}

@include break-down(desktop) { .rla-book-preview .r-resume-features { display: none; } }
