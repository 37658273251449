@import "../../../../scss/_includes";

.rla-book-price-resume {
  display: flex;
  flex-direction: column;
  gap: var(--inner-gap);
}

.rla-book-price-resume .price-list {

  margin: 0;
  display: grid;
  grid-template-columns: auto max-content;
  gap: calc(#{var(--inner-gap)} / 1.25);
  justify-content: space-between;
  align-items: center;
  color: $heading-color;
  font-size: .875rem;
  line-height: 1rem;

  dd {
    margin-inline-start: 0;
    text-align: right;
  }

  &.total {
    font-size: 1rem;
    font-weight: 500; }

  &:has(+ .total) {
    padding: 0 0 var(--inner-gap) 0;
    border-bottom: 1px solid $border-color;
  }

  .tooltip-more {
    display: inline-flex;
    align-items: center;
    gap: .425rem;
    .tabler-icon { width: 1.25rem; height: 1.25rem; color: $business-color; }
  }

  .r-tooltip-trigger { text-decoration: underline; }

}

.rla-book-price-resume .textual-ondemand {
  text-align: center;
  font-size: .825rem;
  font-family: "Inter", sans-serif;
  color: $text-color;
}
