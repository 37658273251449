@import "../../scss/_includes";

.rg-wrapped-list {
  --line-count: 1;
}

.rg-wrapped-list {
  display: grid;
  gap: $common-card-gap;
  grid-template-columns: repeat(var(--line-count), 1fr);
}

.rg-carousel-list, .rg-skeleton-list {
  display: flex;
  gap: $common-card-gap;
  .r-card, .rn-card, .rg-card-skeleton { flex: 0 0 $common-card-width; }
  .rw-card, .rg-review-skeleton { flex: 0 0 21.5rem; }
}

.rg-card-skeleton {
  border-radius: 1.25rem !important;
  &:before {
    content: "";
    display: block;
    padding-top: 120%;
  }
}

.rg-review-skeleton {
  --ctr-skeleton-bg: #{$has-white-color};
  border-radius: 1.25rem !important;
  min-height: 17.5rem;
}

@include break-up(mobile) {
  .rg-wrapped-list {
    --line-count: 2;
  }
}

@include break-up(desktop) {
  .rg-wrapped-list {
    --line-count: 3;
  }
}

@include break-up(big-desktop) {
  .rg-wrapped-list {
    --line-count: 4;
  }
}
