@import "../../../../scss/_includes";

/* common */

.guests-input-choice .r-guests-selector {

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  font-weight: 600;

  &:not(:first-child) { padding-top: 1rem; }
  &:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid $border-color;
  }
}

.guests-input-choice .r-count-stepper {

  display: inline-flex;
  gap: .75rem;
  align-items: center;

  button {

    border: 1.5px solid $border-color;
    border-radius: 50%;
    background: $has-white-color;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 875rem;
    color: $heading-color;
    cursor: pointer;

    .tabler-icon { width: .875rem; height: .875rem; }

    &:disabled { border-color: $border-light-color; }
    &:not(:disabled) { cursor: pointer; }
    &:not(:disabled):hover { border-color: $has-txt-gray-color; }

  }

  .val {
    display: block;
    width: 1.5rem;
    max-width: 1.5rem;
    text-align: center;
  }

}
