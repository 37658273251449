@import "../../scss/_includes";

.rl-error {
  --error-spacing: 1.125rem;
}
.rl-error {
  margin: 0 auto;
  width: 100%;
  max-width: map-get($breakpoints, big-desktop);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rl-error .tabler-icon-error-404 {
  display: block;
  margin: 0 auto;
  max-width: 18.75rem;
  width: 50%;
  height: 50%;
  color: $border-color;
}

.rl-error .error-redirect {
  border-radius: .625rem;
  box-shadow: 10px 8px 168px #0000001C;
  background: $has-white-color;
  padding: var(--error-spacing);
  font-size: .875rem;
  color: $heading-color;
}

.rl-error h1 {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: .375rem;
}

.rl-error .link { display: inline-flex; }

.rl-error .link a {
  display: flex;
  align-items: center;
  min-width: 1.5rem;
  padding-left: .25rem;
  .tabler-icon {
    flex: 0 0 1.125rem;
    height: 1.125rem;
    color: $business-color;
    stroke-width: 2.5;
  }
}
