@import "/src/scss/_includes";

.payment-choice input[type="radio"] {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.payment-choice input[type="radio"][disabled] ~.full-radio { opacity: .375; }

.payment-choice .grouped-radio {
  display: block;
  position: relative;
  line-height: 1.125rem;
  &, & label { cursor: pointer; }
}

.payment-choice .grouped-radio .full-radio {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0 1rem;
  border-radius: .625rem;
  padding: .75rem;
}

.payment-choice .grouped-radio .label { flex: 1 0 50%; }

.payment-choice .grouped-radio .checkmark {
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  background: $business-opacity-bg-color;
}

.payment-choice .grouped-radio .detail {
  display: block;
  width: 100%;
  margin-top: .375rem;
  color: $text-color;
  .block { display: block; }
}

.payment-choice .grouped-radio .label,
.payment-choice .grouped-radio label { font-weight: 500; }

.payment-choice input[type="radio"]:checked ~ .full-radio {
  background: $business-light-bg-color;
  .checkmark {
    border: .325rem solid $business-color;
    background: $has-white-color;
  }
}
