@import "/src/scss/_includes";

.rls-search-filter.skeleton {
  width: 7.5rem;
  .react-loading-skeleton {
    height: 2.6875rem;
    border-radius: 3rem;
  }
}

.rls-search-filter .r-btn-search-filter.badged {
  position: relative;
  > .badge {
    position: absolute;
    top: -.25rem;
    right: -.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: .325rem;
    background: $business-color;
    color: $has-white-color;
    font-size: .825rem;
    font-weight: 500;
  }
}

.rls-filter-item {
  margin-bottom: calc(2.5 * #{var(--inner-spacing)});
  &:last-child { margin-bottom: 0; }
}

.rls-filter-content h2 {
  margin: 0 0 1.25rem 0;
  font-weight: 400;
}

.rls-filter-content h3 {
  margin: 1.25rem 0 .625rem;
  font-size: .875rem;
  font-weight: 400;
}

.rls-filter-content .scrollable {
  max-width: 100%;
  overflow-x: scroll;
  overflow-x: overlay;
}

@media (hover: none) {
  @include break-only(mobile) {
    .rls-filter-item .scrollable {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar { display: none; }
    }
  }
}
