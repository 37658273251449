@import "../../scss/_includes";

.rl-home {
  --layout-gap: 2rem;
}

.rl-home .catch-phrase {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: $heading-color;
}

.rl-home .sub-phrase {
  margin: .625rem 0 0 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  color: $heading-color;
}

.rl-home .rlh-head {

  margin-bottom: var(--layout-gap);

  .search-form {
    position: absolute;
    bottom: var(--x-padding);
    width: calc(100% - ( 2 * #{var(--x-padding)}));
  }

  .rf-searcher { box-shadow: 0 .1rem 1rem rgba($has-white-color, .7); }

}

.rl-home .rlw-shifted-slider:not(.rlh-comments) { margin-bottom: var(--layout-gap); }

.rl-home .rlh-comments {
  margin-top: var(--layout-gap);
  background: $business-opacity-bg-color;
  padding: var(--layout-gap) 0;
  .rw-card { background: $has-white-color; }
}

.rl-home .rlh-comments.rlw-shifted-slider .slider-nav button:not(:hover) { background: $has-white-color; }

.rl-home .rlh-agency {
  background: linear-gradient(to bottom, $business-opacity-bg-color 50%, transparent 50%);
}

@include break-only(mobile, portrait) {
  .rl-home .rlh-head {
    .rlw-backgrounded-img { background-position: 25% center; }
    .catch-phrase { margin-top: var(--x-padding); }
    .search-form { margin-bottom: var(--x-padding); }
  }
}

@include break-up(mobile) {
  .rl-home .catch-phrase {
    margin: calc(.75 * #{var(--x-padding)}) 40% 0 var(--x-padding);
    text-align: left;
  }
  .rl-home .sub-phrase {
    margin-left: var(--x-padding);
    text-align: left;
  }
}


@include  break-up(tablet) {
  .rl-home .catch-phrase {
    font-size: 2rem;
    line-height: 2.125rem;
  }
  .rl-home .sub-phrase { font-size: 1.25rem; }
}


@include  break-up(desktop) {

  .rl-home { --layout-gap: 5rem; }
  .rl-home .rlh-head {
    .catch-phrase { margin: var(--x-padding) 50% 0 var(--x-padding); }
    .sub-phrase { margin-top: calc(.75 * #{var(--x-padding)}); }
  }
}


@include break-up(big-desktop) {
  .rl-home .catch-phrase {
    margin-right: 40% !important;
    font-size: 2.8rem;
    line-height: 3rem;
  }
  .rl-home .rlh-head .search-form {
    bottom: 12.5%;
    max-width: map-get($breakpoints, desktop);
  }
}
