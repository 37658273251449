@import "../../../../scss/_includes";

.rla-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: .25rem;
}

.rla-images .w-img {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.rla-images .w-img:nth-child(1) {
  grid-area: 1 / 1 / 1 / 3;
  border-radius: 1.25rem 1.25rem 0 0;
}

.rla-images .w-img:nth-child(2) {
  grid-area: 2 / 1 / 3 / 2;
  border-radius: 0 0 0 1.25rem;
}

.rla-images .w-img:nth-child(3) {
  grid-area: 2 / 2 / 3 / 3;
  border-radius: 0 0 1.25rem 0;
}

.rla-images .w-img .more {
  position: absolute;
  bottom: .5rem;
  right: .5rem;
  z-index: 1;
  width: max-content;
  display: inline-flex;
  align-items: center;
  gap: .5rem;
  border-radius: 1rem;
  border: none;
  background: $has-white-color;
  padding: .375rem 1rem;
  font-family: "Rubik", sans-serif;
  font-size: .875rem;
  font-weight: 500;
  cursor: pointer;
  color: $heading-color;
  .tabler-icon { width: 1.25rem; height: 1.25rem; }
}

.rli-fullscreen { background: $heading-color; }

.rli-fullscreen .rli-head {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rli-fullscreen .rli-close,
.rli-fullscreen .rli-nav {
  z-index: 1;
  margin: .5rem;
  color: $has-white-color !important;
  cursor: pointer;
}

.rli-fullscreen .rli-state {
  font-family: "Rubik", sans-serif;
  font-size: .875rem;

  color: $has-white-color;
}

.virtual-visit-box {
  cursor: pointer;
  margin: calc(#{var(--layout-gap)} / 2) 0 0 0;
  .r-info-box { display: flex; }
}

@include break-up(desktop) {

  .rla-images {
    grid-template-columns: calc(2/3 * 100%);
    grid-template-rows: auto;
    gap: .5rem;
  }

  .rla-images .w-img:nth-child(1) {
    grid-area: 1 / 1 / 3 / 1;
    border-radius: 1.25rem 0 0 1.25rem;
  }

  .rla-images .w-img:nth-child(2) {
    grid-area: 1 / 2 / 1 / 3;
    border-radius: 0 1.25rem 0 0;
  }

  .rla-images .w-img:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
    border-radius: 0 0 1.25rem 0;
  }

}
