@import "/src/scss/_includes";

.rl-full-overlay {
  z-index: 5000;
  background: black;
}

.rl-full-lightbox {
  z-index: 5001;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
}

.rl-full-lightbox button {

  margin: .5rem;
  border: none;
  background: transparent;
  color: $has-white-color;
  cursor: pointer;

  &:hover {
    background: $business-color;
  }

  &.inactive {
    visibility: hidden;
    pointer-events: none;
  }

}

.rl-full-lightbox .slider-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rl-full-lightbox .slider-content {
  flex: 1;
  display: flex;
  gap: .5rem;
  align-items: center;
  padding: 0 0 4rem 0;
}

.rl-full-lightbox .slider-zone {
  flex: 1;
  align-self: stretch;
  overflow: hidden;
}

.rl-full-lightbox .slider-track {
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
  gap: 0 2rem;
  height: 100%;
}

iframe.slider-item {
  border: none;
  width: 100%;
  height: 100%;
}

@include break-down(mobile) {
  .rl-full-lightbox button.nav {
    position: absolute;
    bottom: 0;
    &.next { right: 0; }
  }
}
