@import "/src/scss/_includes";

.rl-header {
  box-sizing: border-box;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.rl-header .main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rl-header h1 {
  margin: 0;
  a { display: flex; }
  .logo {
    width: 6rem;
    min-width: 6rem;
  }
}

.rl-header nav {
  display: flex;
  align-items: center;
  font-size: .875rem;
  font-weight: 400;
}

.rl-header nav .mb-menu {
  order: 1;
  & ~.rl-h-n-features .user-menu { margin: 0 .325rem; }
}

.rl-h-n-menu {
  list-style-type: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 1.625rem;
}

.rl-header nav .rl-h-n-features {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.rl-header .agency-filter {
  margin-top: 1.25rem;
  .back-context { width: 100%; }
  & > *:not(.back-context) { display: none; }
}

@include break-down(small-mobile) {
  .rl-header h1 {
    flex: 0 0 26px;
    &, & > a { overflow: hidden; }
  }
}


@include break-up(mobile) {
  .rl-header h1 .logo { width: 7.5rem; }
  .rl-header nav, .rl-header nav .rl-h-n-features { gap: .475rem; }
}


@include break-up(desktop) {
  .rl-header { margin-bottom: 2rem; }
  .rl-header h1 .logo { width: 11rem; }
}
