@import "../../../scss/_includes";

.r-card {
  --card-spacing: 1rem;
}

.r-card {
  display: flex;
  flex-direction: column;
  gap: var(--card-spacing);
}

.r-card h3 {
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: $heading-color;
}

.r-card .r-img { border-radius: .625rem; }

.r-card .about {
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  color: $text-color;
  line-height: 1.35;
}

.r-card .more {
  font-family: "Rubik", sans-serif;
  margin: var(--card-spacing) 0 0 0;
}


@include break-up(mobile) {

  .r-card {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;

    h3 { grid-area: 1 / 1 / 2 / 2; }
    .r-img { grid-area: 2 / 1 / 3 / 2; }
    .about { grid-area: 2 / 2 / 3 / 3; }

    &.no-image {
      .about { grid-area: 1 / 2 / 2 / 3; }
    }
  }

  .r-card h3 { text-align: right; }
  .r-card .r-img {
    justify-self: flex-end;
    width: 100%;
  }

}


@include break-up(desktop) {
  .r-card {
    --card-spacing: 1.25rem;
  }
}
