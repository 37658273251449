@import "../../../../scss/_includes";

.rla-costs .costs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  & > .a-cost {
    flex: 100%;
    min-height: 1.5rem;
  }
}

.rla-costs .a-cost,
.rla-costs .costs-additional div {
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  font-weight: 400;
  > span { display: block; }
  > span:first-of-type {
    font-weight: 600;
    padding-bottom: .325rem;
  }
  > span:last-of-type::first-letter { text-transform: uppercase; }
}

.rla-costs .costs-additional span {
  display: block;
  padding: 2rem 0 1.25rem 0;
  font-family: "Rubik", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: $heading-color;
  text-transform: uppercase;
}

@include break-up(desktop) {
  .rla-costs .costs-list > .a-cost { flex: 0 0 calc(50% - 1.5rem); }
}
