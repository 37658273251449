@import "../../../scss/_includes";

.rl-cart {
  --item-spacing: 1.125rem;
  --col-spacing-rate: 2;
}

.rl-cart {
  margin: 0 auto;
  width: 100%;
  max-width: map-get($breakpoints, big-desktop);
}

.rl-cart h2 {
  margin: 0 0 1rem 0;
  font-size: 1.125rem;
  font-weight: 400;
  text-transform: uppercase;
  color: $has-txt-gray-color;
}
.cart-item-wrapper {

  padding: var(--item-spacing);
  border-radius: .625rem;
  box-shadow: 10px 8px 168px #0000001C;
  background: $has-white-color;
  font-size: .875rem;
  color: $heading-color;

}

.cart-item-wrapper ~ .cart-item-wrapper,
.rl-cart .rl-two-col ~ .rl-two-col,
.cart-item-wrapper.margin { margin-top: calc(2 * #{var(--item-spacing)}); }

.booking-process { margin-bottom: calc(3 * #{var(--item-spacing)}); }

.rl-cart .rl-two-col {
  display: flex;
  flex-direction: column;
  gap: var(--item-spacing);
}

.rl-cart .rl-two-col.separator {
  gap: calc(3 * #{var(--item-spacing)});
  > * { position: relative; }
  > * ~ *:before {
    content: "";
    position: absolute;
    top: calc(-1.5 * #{var(--item-spacing)});
    height: 1.5px;
    width: 100%;
    background: $border-color;
  }
}

.rl-cart .stepper-actions button.step-btn {
  font-size: 1rem;
  font-weight: 500;
  border-radius: 4rem;
  border: 1.5px solid $business-color;
  background: $business-color;
  padding: 1.25rem 1rem;
  color: $has-white-color;
  cursor: pointer;

  &.back {
    border-color: $has-black-color;
    background: $has-white-color;
    color: $has-black-color;
  }

  &:disabled {
    opacity: .5;
    pointer-events: none;
  }

  .loading-action-tabler {
    width: .875rem;
    height: .875rem;
    scale: 2;
    stroke-width: 1.5px;
    animation: rotate .5s linear infinite;
  }
}

@include break-up(mobile) {

  .rl-cart .rl-two-col {
    flex-direction: row;
    gap: calc(#{var(--item-spacing)} * #{var(--col-spacing-rate)}) !important;
    & > * { flex: 1 1 50%; }
  }

  .rl-cart .rl-two-col.separator {
    > * ~ *:before {
      top: 0;
      left: calc( -0.5 * (#{var(--item-spacing)} * #{var(--col-spacing-rate)}));
      height: 100%;
      width: 1.5px;
    }
  }
}


@include break-up(tablet) {

  .rl-cart {
    --item-spacing: 1.5rem;
  }

}


@include break-up(desktop) {

  .rl-cart {
    --col-spacing-rate: 4;
  }

}
