@import "../../../scss/_includes";

.cart-item {
  --cart-spacing: 1.125rem;
  --cart-inner-spacing: 1rem;
  --cart-col-spacing-rate: 2;
}

.cart-item h1 {
  margin: 0;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: $heading-color;
}

.cart-item .desc {
  display: inline-block;
  margin-top: .325rem;
  color: $has-txt-gray-color;
}

.cart-item .cart-item-resume .r-img { border-radius: .625rem; }

.cart-item .cart-item-details {
  display: flex;
  flex-direction: column;
  gap: var(--cart-spacing);
  margin-top: var(--cart-spacing);
}

.cart-item .cart-item-resume {
  display: grid;
  gap: var(--cart-inner-spacing);
}

.cart-item .cart-item-resume .actions {
  display: inline-flex;
  gap: var(--cart-inner-spacing);
  justify-content: stretch;
  button { flex: 1; }
}

.cart-item .cart-item-resume .period {
  display: flex;
  justify-content: space-between;
  .label {
    color: $has-txt-gray-color;
    text-transform: uppercase;
  }
  .value {
    font-weight: 500;
    font-size: 1rem;
  }
}

.cart-item .cart-item-lifetime { min-height: 3rem; }

.cart-item .cart-item-price {
  display: flex;
  flex-direction: column;
  gap: calc(2 * #{var(--cart-inner-spacing)});
  .cart-price-detail.charge ~ .last-minute-warning { margin-top: calc(-1.25 * #{var(--cart-inner-spacing)}); }
  .stepper-actions {
    margin-top: auto;
    button { width: 100%; }
  }
}

.cart-item .r-btn.loadable-keeping-size {
  position: relative;
  &:not(.loading) { .loader { display: none; } }
  &.loading {
    pointer-events: none;
    .text { visibility: hidden; }
    .loader {
      position: absolute;
      display: flex;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .tabler-icon { animation: rotate .5s linear infinite; }
    }
  }
}

@include break-up(mobile) {

  .cart-item .cart-item-details {
    flex-direction: row;
    gap: calc(#{var(--cart-spacing)} * #{var(--cart-col-spacing-rate)});
    & > * { flex: 1 1 50%; }
  }

}


@include break-up(desktop) {

  .cart-item {
    ---cart-spacing: 2rem;
    --cart-col-spacing-rate: 4;
  }

  .cart-item h1 {
    font-size: 2rem;
    line-height: 2.125rem;
  }

}

@include break-up(big-desktop) {

  .cart-item .cart-item-resume {
    grid-template-rows: repeat(3, min-content);
    grid-template-columns: repeat(2, auto);
    .r-img { grid-area: 1/1/2/3; }
    .period { grid-area: 2/1/2/2; }
    .actions { grid-area: 2/2/2/3; }
    .cart-item-lifetime { grid-area: 3/1/3/3; }
  }

  .cart-item .cart-item-resume .period {
    flex-direction: column;
    justify-content: center;
  }

}
