@import "../../scss/_includes";

/* titles */

.cms-grid, .cms-page {
  --layout-gap: 3rem;
}

.cms-grid .descripted-title,
.cms-page .descripted-title,
.cms-page .inner-content {
  max-width: map-get($breakpoints, big-desktop);
  margin-left: auto;
  margin-right: auto;
}

.cms-grid .descripted-title,
.cms-page .descripted-title {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(#{var(--layout-gap)} * .5);

  h1, .title {
    margin: 0;
    font-size: inherit;
  }

  .rl-lead {
    font-family: "Inter", sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: $text-color;
    p { margin: 0; }
    p ~ p { margin-top: .75rem; }
    p.bolder { font-weight: 600; }
  }

}


/* list pages grid */

.cms-grid {
  --list-space: 3rem;
}

.cms-grid .cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--list-space);
}


@include break-up(big-desktop) {

  .cms-grid .cards-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    & > * { flex: 0 1 calc(50% - (#{var(--list-space)} / 2)); }
  }
}


/* open page map */

.cms-map {
  --map-height: 19rem;
  --map-margin: 2.5rem;
}

.cms-map { margin-top: var(--map-margin); }
.cms-page:not(:has(.inner-content)) ~.cms-map { margin-top: 0; }

.cms-map .map-box {
  height: var(--map-height);
  border-radius: 2rem;
  overflow: hidden;
}


@include break-up(mobile) {
  .cms-map {
    --map-height: 23.75rem;
  }
}

@include break-up(desktop) {
  .cms-map {
    --map-margin: 3.25rem;
  }
}

@include break-up(big-screen) {
  .cms-map {
    --map-margin: 3.75rem;
  }
}
