@import "../../../../scss/_includes";

$border-radius: 3rem;
$reset-size: 1.5rem;

.rf-searcher .resetter,
.rf-searcher-mobile-modal-wrap .resetter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $reset-size;
  height: $reset-size;
  border-radius: 50%;
  border: none;
  background: $border-color;
  cursor: pointer;
  color: $heading-color;
  .tabler-icon {
    flex: 0 0 .75rem;
    height: .75rem;
    stroke-width: 4px;
  }
}

.rf-searcher .input-wrap {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.rf-searcher.mobile .input-wrap {

  min-height: 1.5rem;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: min-content 1fr;
  gap: .225rem 0;
  font-size: .825rem;
  font-weight: 400;
  color: $text-color;

  .r-btn { grid-area: 1 / 1 / 3 / 2; }
  .others { align-self: flex-start; }
  .resort {
    align-self: flex-end;
    font-size: 1rem;
    font-weight: 500;
    color: $heading-color;
  }

}

.rf-searcher .input-wrap:not(:first-of-type):before {
  content: "";
  display: flex;
  flex: 0 0 1.5px;
  height: 65%;
  background: $border-color;
}

.rf-searcher .input-wrap .input-content {
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: .75rem;
  box-sizing: border-box;
  height: 100%;
  border-radius: $border-radius;
  padding-left: 1rem !important;
  padding-right: calc(1.5rem + #{$reset-size}) !important;
  cursor: pointer;
  & > .tabler-icon { flex: 0 0 auto; }
}

.rf-searcher .input-wrap .input-label {
  margin-bottom: .125rem;
  text-transform: uppercase;
}

.rf-searcher .input-wrap .input-val-state {
  display: grid;
  grid-template: 1fr / 1fr;
  .value { font-weight: 600; }
  &.empty .label { color: $has-txt-gray-color; }
  &:not(.empty) .label { display: none; }
}

.rf-searcher .input-wrap .resetter {
  position: absolute;
  right: 1rem;
}

/* input dropdown content */

.rf-searcher-input-dropdown {
  z-index: 2;
  width: fit-content;
  height: fit-content;
  border-radius: 1.25rem;
  background: $has-white-color;
  @include boxing-shadow(.5px);
  padding: 1.5rem;
  font-size: .875rem;
  color: $heading-color;
}


/* mobile modal input display */

.rf-searcher-mobile-modal-wrap .input-wrap {
  border-radius: .625rem;
  border: 1px solid $border-color;
  background: $border-light-color;
  display: flex;
  flex-direction: column;
  gap: .75rem;
  padding: 1rem .75rem;
  font-size: .825rem;
  outline: none;
}

.rf-searcher-mobile-modal-wrap .input-wrap.selection h2 {
  margin: 0;
  font-size: 1.125rem;
}

.rf-searcher-mobile-modal-wrap .input-wrap:not(.selection) {
  flex-direction: row;
  align-items: center;
  min-height: 1.5rem;
}

.rf-searcher-mobile-modal-wrap .input-wrap .input-label { font-size: .875rem; }

.rf-searcher-mobile-modal-wrap .input-wrap .input-val-state {
  flex: 1;
  text-align: right;
  .value { font-weight: 600; }
  &.empty .label { color: $text-color; }
  &:not(.empty) .label { display: none; }
}
