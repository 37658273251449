@import "/src/scss/_includes";

.rl-footer {
  box-sizing: border-box;
  width: 100%;
  margin-top: 3.75rem;
}

.rl-footer .rlw-backgrounded-wrapper {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: .75rem;
  color: $text-color;
}

.rl-footer .logo { width: 8rem; }
.rl-footer a { font-weight: 600; }
.rl-footer .copy {
  text-align: center;
  line-height: 1.4;
  a { padding: 0 .5rem; }
}

.rl-footer .terms {
  text-align: center;
  font-size: .875rem;
  line-height: 1.4;
}

.rl-footer .socials {
  display: inline-flex;
  list-style-type: none;
  a { display: block; }
  .tabler-icon {
    width: 2rem;
    height: 2rem;
    stroke-width: 1.5;
  }
}

@include break-up(mobile) {
  .rl-footer { margin-top: 5rem; }
}


@include break-up(tablet) {
  .rl-footer { margin-top: 7rem; }
}

@include break-up(big-desktop) {
  .rl-footer { margin-top: 9rem; }
}
