@import "../../../scss/_includes";

.a-card {
  --card-spacing: 1rem;
}

.a-card {
  display: flex;
  flex-direction: column;
  gap: var(--card-spacing);
}

.a-card h3 {
  font-family: "Rubik", sans-serif;
  font-size: .875rem;
  font-weight: 500;
  color: $heading-color;
  margin: 0 0 .425rem;
}

.a-card .metas .logo {
  border-radius: .625rem;

  a {
    display: block;
    font-weight: 500;
    font-size: 1.5rem;
  }

  &:not(.empty) img {
    width: auto;
    max-height: 6rem;
    max-width: 100%;
  }

}

.a-card .metas .about {
  margin-top: var(--card-spacing);
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  color: $text-color;
  line-height: 1.35;
}

.a-card .metas .more { margin: var(--card-spacing) 0 0 0; }

.a-card .cbox-skeleton {
  border-radius: .625rem;
  height: 15rem;
}

@include break-up(mobile) {

  .a-card {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    .metas { grid-area: 1 / 1 / 2 / 2; }
  }

  .a-card .metas {
    text-align: right;
    .logo { margin: var(--card-spacing) 0 0 auto; }
    .more { margin-left: auto; }
  }

}

@include break-up(desktop) {
  .a-card {
    --card-spacing: 1.25rem;
  }
}
