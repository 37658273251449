@import "../../../scss/_includes";

.rla-sharing h1 { margin-bottom: 0; }

.rla-sharing .rla-choice-resume { margin: 0 0 1.75rem 0; }

.rla-sharing .article {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1.75rem 0;
}

.rla-sharing .r-img {
  border-radius: .625rem;
  flex: 0 0 4rem;
}

.rla-sharing .article .name {
  font-size: .875rem;
  & > * { display: block; }
  .location {
    font-size: .825rem;
    color: $text-color;
  }
}

.rla-sharing .action-group {
  overflow: hidden;
  border-radius: .5rem;
  border: 1px solid $border-color;
  & ~ .action-group { margin-top: .625rem; }
}

.rla-sharing .action-group button {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border: none;
  background: $border-light-color;
  padding: .5rem;
  font-size: .875rem;
  font-weight: 500;
  cursor: pointer;
  & ~ button { border-top: 1px solid $border-color; }
}

.copy-success {
  flex: 1 1 auto;
  text-align: right;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: .5rem;
  font-size: .825rem;
  color: $text-color;
  .tabler-icon { width: 1rem; height: 1rem; stroke-width: 4; color: $valid-color; }
}
