@import "../../../scss/_includes";

.rl-agency .contact {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}


@include break-up(tablet) {
  .rl-agency .contact {
    flex-direction: row;
    align-items: flex-start;
    .agency-cbox { min-width: 25%; }
    .r-form { flex: 1; }
  }
}
