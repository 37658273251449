@import "/src/scss/_includes";

.rl-profile {
  --skeleton-height: 32rem;
  --profile-spacing: #{map-get($x-paddings, mobile)};
}

.rl-profile {
  max-width: map-get($breakpoints, big-desktop);
  .rlw-title { margin-bottom: .75rem; }
}

.rl-profile .profile-skeleton {
  min-height: var(--skeleton-height);
  border-radius: .625rem !important;
}

.rl-profile .form-wrapper {
  border-radius: .625rem;
  box-shadow: 10px 8px 168px #0000001C;
  background: $has-white-color;
  padding: var(--profile-spacing);
}


@include break-up(mobile) {
  .rl-profile {
    --profile-spacing: #{map-get($x-paddings, tablet)};
  }
}


@include break-up(tablet) {
  .rl-profile {
    --skeleton-height: 24rem;
    --profile-spacing: #{map-get($x-paddings, desktop)};
  }
}
