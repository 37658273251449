@import "/src/scss/_includes";


.rl-article {
  --map-height: 19rem;
  --layout-gap: 2rem;
  --booking-box-margin: #{map-get($x-paddings, mobile)};
  --booking-box-padding: .75rem;
  --booking-box-width: 16.25rem;
}


/* all data content layout */

.rl-article .rla-map { margin-bottom: var(--layout-gap); }

.rl-article .map-box {

  --ctl-map-control-radius: 2rem;
  overflow: hidden;
  height: var(--map-height);
  transform: translate3d(0px, 0px, 0px);
  border-radius: 2rem !important;

  .ct-map__container { height: 100% !important; }
  &.back { background: $skeleton-bg-color; }

  &.imaged {
    background-position: center;
    background-size: cover;
  }
}

.rl-article .rla-content.rla-two-col {

  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  gap: var(--layout-gap);

  .rla-data { flex: 1 1 auto; }

}

.rl-article .rla-data .rla-separator {

  &:not(:first-child) { padding-bottom: var(--layout-gap); }

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
    padding-bottom: var(--layout-gap);
    &:not(.blank) { margin-bottom: var(--layout-gap); }
    &.blank { border-color: transparent; }
  }

  &:last-child { padding-bottom: 0; }

}

.rl-article .rla-complements {

  margin-top: var(--layout-gap);

  .rla-separator {
    border-top: 1px solid $border-color;
    padding-top: var(--layout-gap);
  }

  .rla-reviews-wrapper,
  .rla-comments-wrapper .slider-content { margin-bottom: var(--layout-gap); }
}

.rl-article h2 {
  font-weight: 500;
  font-size: .875rem;
  text-transform: uppercase;
  color: $business-color;
  margin: 0 0 calc(#{var(--layout-gap)} / 1.5) 0;
}

.rl-article .rla-comments .rw-card.show-all {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: $heading-color;
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  font-size: .875rem;
  span {}
}

/* booking box view on both sticky options */

.rla-sticky-booking {
  box-sizing: border-box;
  border-radius: 1.25rem;
  background: $has-white-color;
  @include boxing-shadow();
  z-index: 1;
  &.hidden {
    @include boxing-shadow(0);
    visibility: hidden;
  }
  .rla-booking-wrapper { padding: var(--booking-box-padding); }
}

.rla-sticky-booking.mobile {
  $padding: calc(#{var(--x-padding)} / 2);
  position: sticky;
  bottom: $padding;
  margin-top: var(--layout-gap);
  margin-right: calc(-1 * #{$padding});
  margin-left: calc(-1 * #{$padding});
}

.rla-sticky-booking:not(.mobile) {
  position: sticky;
  top: var(--booking-box-margin);
  min-width: var(--booking-box-width);
  max-width: var(--booking-box-width);
  margin-right: var(--booking-box-margin);
  flex-grow: 0;
  flex-shrink: 0;
}

@include break-up(mobile) {
  .rl-article {
    --booking-box-margin: #{map-get($x-paddings, mobile)};
  }
}

@include break-up(tablet) {
  .rl-article { --map-height: 25.5rem; }
  .rla-sticky-booking:not(.mobile) {
    margin-top: calc((-1 * #{var(--map-height)}) - #{var(--layout-gap)} + #{var(--booking-box-margin)});
  }
}

@include break-up(desktop) {
  .rl-article {
    --booking-box-margin: #{map-get($x-paddings, tablet)};
    --map-height: 32rem;
    --booking-box-padding: .75rem;
    --booking-box-width: 20rem;
  }
}


@include break-up(big-desktop) {
  .rl-article {
    --booking-box-margin: #{map-get($x-paddings, desktop)};
    --booking-box-width: 23.75rem;
  }
}
