@import "~normalize.css";
@import "~react-loading-skeleton/dist/skeleton.css";
@import "~@ct-react/calendar/dist/ct-react.calendar.css";
@import "_includes";

html, body {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $text-color;
}

ul {
  margin: 0;
  padding-inline-start: 0;
}

a, a:hover, a:active {
  color: $heading-color !important;
  text-decoration: none;
}

a.disabled {
  pointer-events: none;
  color: $text-color;
}

.ellipsed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:root {
  --ctr-skeleton-bg: #{$skeleton-bg-color};
  --ctr-skeleton-hl: #{$skeleton-hl-color};
  --ctl-calendar-font-shorthand: normal normal 400 .875rem/1.125rem "Rubik", sans-serif;
  --ctl-calendar-range-color: #{$business-color};
  --ctl-calendar-range-light-color: #{$business-light-bg-color};
  --ctl-calendar-txt-color: #{$text-color};
}

.ct-calendar__controllers__calendarWeekHeader,
.ct-calendar__controllers__calendarMonthCaption,
.ct-calendar__controllers__calendarNavigationButton { color: $heading-color; }
.ct-calendar__controllers__calendarNavigationButton:not(:disabled):hover { background: $border-light-color; }
.ct-calendar__controllers__calendarDay.cds-out-of-range,
.ct-calendar__controllers__calendarDay.cds-blocked:not(.cds-blocked-booked){ text-decoration: none !important; }
.ct-calendar__controllers__calendarDay.cds-selected-into { font-weight: 500 !important; }

/* main layout */

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > .main-wrap {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

.flex-skeleton { width: 100%; flex: 1; }

/* scrollbar */

html {
  scrollbar-width: thin;
  scrollbar-color: $business-color transparent;
}

::-webkit-scrollbar {
  width: .875rem;
  height: .875rem;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb {
  border-radius: .875rem;
  border: .25rem solid transparent;
  background-color: rgba($business-color, .75);
  background-clip: content-box;
  &:hover { background-color: $business-color; }
}

/* html descriptions */

.r-format-html {

  div, p, span, a, li, dt, dd, strong, em, i, u, b, blockquote, code, pre  {
    background-color: transparent !important;
    font-family: 'Inter', sans-serif !important;
    font-size: .875rem !important;
    line-height: 1.25rem !important;
    font-weight: 400 !important;
    color: $text-color !important;
    text-align: left;
  }

  strong, b, strong > *, b > * {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
  }

  p ~ br, p > br:first-child, p > br:last-child { display: none; }

  p {
    margin: 1rem 0 1rem 0;
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }

  pre { text-wrap: unset; }
  ul { padding-inline-start: 1rem }
  hr { display: none !important; }

}
