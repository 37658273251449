@import "/src/scss/_includes";

.expired-cart-modal .expired-message {
  display: flex;
  gap: .375rem;
  align-items: center;
  border-radius: .375rem;
  padding: .5rem;
  background: $error-bg-color;
  color: $error-color;
  font-size: .875rem;
  font-weight: 500;
  .tabler-icon { width: 2rem; height: 2rem; }
}

.expired-cart-modal p {
  margin: 1.5rem 0 0 0;
  font-size: .875rem;
  line-height: 1.325;
  color: $heading-color;
}
