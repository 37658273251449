@import "../../../../scss/_includes";

.rla-agency {
  --inner-gap: 1rem;
}

.rla-agency .agency-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--inner-gap);
}

.rla-agency .logo {
  border-radius: .625rem;
  a { display: block; }
  img {
    width: auto;
    max-height: 6rem;
    max-width: 100%;
  }
}

.rla-agency h3 {
  font-size: .875rem;
  font-weight: 500;
  color: $heading-color;
  margin: 0 0 .425rem;
}

.rla-agency .about {
  font-size: .875rem;
  color: $text-color;
  line-height: 1.35;
}


@include break-up(mobile) {

  .rla-agency .agency-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, min-content);
    .logo { grid-area: 1 / 1 / 2 / 2; }
    .about { grid-area: 2 / 1 / 5 / 2; }
    .agency-cbox { grid-area: 1 / 2 / 3 / 3; }
    &.no-lead .agency-cbox { grid-area: 1 / 1 / 2 / 3; }
    &.no-lead .r-btn { grid-area: 2 / 1 / 2 / 3; }
  }

  .rla-agency .agency-wrapper .logo { margin: 0 0 0 auto; }
  .rla-agency .agency-wrapper .about { text-align: right; }

}


@include break-up(desktop) {

  .rla-agency {
    --inner-gap: 1.75rem;
  }

  .rla-agency .agency-wrapper {
    row-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, min-content);
    .logo { grid-area: 1 / 1 / 2 / 1; }
    .about { grid-area: 2 / 1 / 3 / 1; }
    .agency-cbox { grid-area: 1 / 2 / 4 / 2; }
    .form-wrapper { grid-area: 1 / 3 / 4 / 5; }
    &.no-lead .agency-cbox { grid-area: 1 / 1 / 4 / 3; }
  }

}
