@import "/src/scss/_includes";

.rls-form {
  box-sizing: border-box;
  margin: 0 auto 2rem;
  width: 100%;
  max-width: map-get($breakpoints, desktop);
}

.rls-form h3 {
  margin: 0 0 .5rem .75rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: $heading-color;
}

.rls-result-wrapper {

  &.map-only .rls-result-list,
  &:not(.map-only) .rls-result-map { display: none; }
}

.rls-result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(1 * #{$common-card-gap});
}

.rls-result-footer {
  margin-top: calc(2 * #{$common-card-gap});
  text-align: center;
}

.rls-result-map {
  border-radius: 2rem;
  background: var(--ctr-skeleton-bg);
  overflow: hidden;
  position: sticky;
  top: $common-card-gap;
  height: calc(100vh - 2 * #{$common-card-gap}) !important;

  .ct-map__container { height: 100% !important; }
  .ct-map__popupWrapper.rls-map-popup {
    .leaflet-popup-content-wrapper {
      overflow: hidden;
      border-radius: 1.25rem;
      width: 18rem;
      padding: 0;
    }
  }
}

@include break-only(mobile) {
  .rls-result-header.empty {
    flex-direction: column;
    & > div:not(.rls-search-filter) { order: 1; }
    .rls-search-filter {
      order: 0;
      align-self: flex-end;
    }
  }
}


@include break-up(mobile) {
  .rls-result-list .rg-wrapped-list {
    --line-count: 2;
  }
}

@include break-up(desktop, landscape) {
  .rls-result-list .rg-wrapped-list {
    --line-count: 1;
  }
}


@include break-up(desktop) {

  .rls-form { margin-bottom: 3rem; }

  .rls-result-wrapper {

    display: flex;
    align-items: flex-start;
    gap: calc(2 * #{$common-card-gap});

    .rls-result-list { flex: 1 1 35%; }
    .rls-result-map { flex: 1 1 65%; }
    &:not(.map-only) .rls-result-map { display: block; }
    &.map-only {
      .rls-result-list { display: none; }
      .rls-result-map { flex: 100%; }
    }
  }

}


@include break-up(big-desktop) {

  .rls-result-list .rg-wrapped-list {
    --line-count: 2;
  }

  .rls-result-wrapper .rls-result-list,
  .rls-result-wrapper .rls-result-map { flex: 1 1 50%; }

}
