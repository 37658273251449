@import "../../../../scss/_includes";

/* selection */

.rf-searcher-input-dropdown.period, .rf-searcher-mobile-modal-wrap .input-wrap.period.selection {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.period-input-choice.header {
  display: flex;
  gap: .25rem;
  border-radius: 1.25rem;
  background: $border-color;
  padding: .25rem;
}

.period-input-choice.header button {
  flex: 1 1 50%;
  border-radius: 1.25rem;
  border: none;
  background: transparent;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
  color: $heading-color;
  cursor: pointer;
  &.active { background: $has-white-color; }
}

.period-input-choice.content {
  max-width: 39.75rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: .75rem;
  &.flex {
    margin-top: .75rem;
    align-items: center;
    gap: 1.5rem;
  }
}

.period-input-choice.content .r-btn-choice {
  line-height: 2rem;
  border-radius: 1.25rem;
  border: 1.5px solid $border-color;
  background: $has-white-color;
  font-size: .825rem;
  padding: 0 .75rem;
  cursor: pointer;
  color: $heading-color;
  &:not(.active):hover { border-color: $has-txt-gray-color; }
  &.active {
    background: $border-light-color;
    border-color: $has-txt-gray-color;
    box-shadow: inset 0 0 0 1px $has-txt-gray-color;
  }
}

.period-input-choice.content .r-btn-choice.month {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  width: 4rem;
  height: 4rem;
  box-sizing: content-box;
  padding: 1rem;
  line-height: 1.1;
  text-transform: capitalize;
  .tabler-icon { width: 2rem; height: 2rem; flex: 0 0 auto; }
}

.period-input-choice.content .r-flex-group {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: .75rem;
  align-items: center;
  & > span {
    font-size: 1.125rem;
    font-weight: 500;
  }
}

.period-input-choice.content .r-choice-wrapper {
  display: inline-flex;
  gap: .75rem;
  & > * { flex: 1 0 auto; }
}

.period-input-choice.content .scrollable {
  max-width: 100%;
  overflow-x: scroll;
  overflow-x: overlay;
}

/* desktop */

.rf-searcher .input-wrap.period { min-width: 25%; }
.rf-searcher .input-wrap.period .input-val-state.empty .label {
  color: $heading-color;
  font-weight: 600;
}

.rf-searcher-input-dropdown.period { align-items: center; }

.rf-searcher-input-dropdown.period .period-input-choice.header {
  display: inline-flex;
  justify-content: center;
  button { min-width: 10rem; }
}


/* mobile */

.rf-searcher-mobile-modal-wrap .input-wrap.period .input-val-state .label { display: none !important; }
.rf-searcher-mobile-modal-wrap .input-wrap.period .ct-calendar__controllers__calendarWrapper { margin: 0 auto; }
.rf-searcher-mobile-modal-wrap .input-wrap.period .period-input-choice.content .scrollable {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar { display: none; }
}
