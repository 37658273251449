@import "../../../scss/_includes";

.rw-card {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-sizing: border-box;
  border-radius: 1.25rem;
  background: rgba($has-bg-gray-color, .1);
  padding: 1.5rem;
  font-family: "Rubik", sans-serif;
  font-size: .875rem;
  font-weight: 400;
}

.rw-card .client {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $heading-color;
}

.rw-card .client .name {
  display: flex;
  align-items: center;
  gap: .75rem;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: .175rem;
}

.rw-card .client .name .rate {
  display: inline-flex;
  align-items: center;
  gap: .325rem;
  font-size: .875rem;
  .tabler-icon {
    stroke: #f8d51e;
    stroke-width: 3;
    width: 1rem;
    height: 1rem;
  }
}

.rw-card .client .date { text-transform: capitalize; }

.rw-card .comment {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0;
  line-height: 1.5;
  white-space: pre-line;
  color: $text-color;
}

.rw-card .to-modal {
  align-self: flex-end;
  background: $border-light-color;
  text-decoration: none;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  &:hover { background: $border-color; }
}

.rw-card .article {
  display: flex;
  align-items: center;
  gap: .75rem;
  margin-top: auto;
  border-radius: .625rem;
  background: $border-light-color;
}

.rw-card .article .picto {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 1.75rem;
  height: 1.75rem;
  border-radius: .625rem;
  padding: .75rem;
  color: $business-color;

  .tabler-icon {
    z-index: 1;
    width: 1.75rem;
    height: 1.75rem;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 150%;
    height: 150%;
    top: 0;
    right: 0;
    margin-top: -25%;
    border-radius: 50%;
    background: $business-light-bg-color;
  }
}
.rw-card .article .detail { padding-right: .75rem; }
.rw-card .article .detail > * { display: block; }
.rw-card .article .region { font-size: .825rem; }
.rw-card .article .title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 500;
}
