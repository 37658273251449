@import "../../../scss/_includes";

.r-img {

  display: block;
  position: relative;
  overflow: hidden;
  background: $skeleton-bg-color;

  &:before {
    content: "";
    display: block;
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // landscape
  &:not(.ratio-card):not(.ratio-square) > img {
    width: 100%;
    min-width: unset;
    height: auto;
    min-height: 100%;
  }

  // portrait
  &.ratio-card > img {
    height: 100%;
    min-height: unset;
    width: auto;
    min-width: 100%;
  }

  &.ratio-4-3 {
    aspect-ratio: 4/3;
    &:before { padding-top: 75%; }

  }

  &.ratio-3-2 {
    aspect-ratio: 3/2;
    &:before {padding-top: 66.66%; }
  }

  &.ratio-16-9 {
    aspect-ratio: 16/9;
    &:before { padding-top: 56.25%; }
  }

  &.ratio-9-16 {
    aspect-ratio: 9/16;
    &:before {padding-top: 177.77%; }
  }

  &.ratio-card {
    aspect-ratio: 5/6;
    &:before { padding-top: 120%; }
  }

  &.ratio-square {
    aspect-ratio: 1/1;
    &:before { padding-top: 100%; }
    > img { max-height: 100%; }
  }

}
